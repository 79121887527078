var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 mt-4 pb-2"
  }, [_c('h5', {
    staticClass: "text-center text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("upload-documents")))]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-end"
  }, [[0, 1, 4].includes(_vm.amendment_status) ? _c('div', {
    staticClass: "col-lg-12 d-flex justify-content-end"
  }, [_c('b-btn', {
    staticClass: "btn-sm btn-primary",
    on: {
      "click": _vm.btnmuatnaikdoc
    }
  }, [_vm._v(_vm._s(_vm.$t("upload-documents")))])], 1) : _vm._e()]), _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-hover table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticClass: "text-left",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("doc-name")) + " / " + _vm._s(_vm.$t("appendix")) + " ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("action")))])])]), _vm.doc_array.length == 0 ? _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(_vm._s(_vm.$t("no-docs")))])])])]) : _vm._e(), _vm.doc_array.length > 0 ? _c('tbody', _vm._l(_vm.doc_array, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-left",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [_c('b-button', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.display(item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]), [0, 1, 4].includes(_vm.amendment_status) ? _c('b-button', {
      staticClass: "btn btn-danger btn-sm",
      attrs: {
        "tag": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteDoc(index, item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e()], 1)])]);
  }), 0) : _vm._e()])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1, 4].includes(_vm.amendment_status) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'pindaan.part_d-tidakalih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save-next")))]) : _vm._e()], 1)])])])]), _c('b-modal', {
    ref: "modalMuatNaik",
    attrs: {
      "title": "Muat Naik Dokumen",
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.hideModal,
      "cancel": _vm.hideModalDoc
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.doc.description.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "description"
    }
  }, [_vm._v(_vm._s(_vm.$t("doc-name")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.doc.description.$model,
      expression: "$v.doc.description.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "description",
      "id": "description",
      "placeholder": "Nama Dokumen"
    },
    domProps: {
      "value": _vm.$v.doc.description.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.doc.description, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.doc.description.$error && !_vm.$v.doc.description.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("doc-name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('label', {
    staticClass: "upload-photo-dark-text btn-sm btn-info btn-upload",
    attrs: {
      "for": "upload-photo"
    }
  }, [_vm._v("+ " + _vm._s(_vm.$t("add-doc"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo",
      "accept": "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  }), _c('div', {
    staticClass: "mt-3"
  }, [_vm._v(_vm._s(_vm.$t("selected-file")) + ": " + _vm._s(_vm.file ? _vm.file : "-"))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }